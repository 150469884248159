.slider-container {
  width: 100%;
  margin: auto;
  padding: 20px 0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 10px;
  background-color: #fff;
  color: #333;
}
.slick-slide img {
    display: inline-block !important;
}

.card {
    width: 85% !important;
    font-size: 14px;
    padding: 20px;
    height: 500px;
    overflow: scroll;
}
.card img {
    width: 200px !important;
    height: 200px !important;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
    border: dashed 3px #fdb827;
}
.card h3 {
    color: #4b90cd;
}
.card p {
    color: #002d62;
    text-align: justify;
}
.slick-prev, .slick-next {
  color: #002d62;
  font-size: 24px;
} 
.slick-dots {
  bottom: -30px;
}
.slick-dots li button:before {
  font-size: 12px;
  color: #4b90cd;
}
.slick-dots li.slick-active button:before {
  color: #fdb827;
}

@media screen and (max-width: 992px) {
	
}

