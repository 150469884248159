html {
  scroll-behavior: smooth;
}
h1 {
    
}
h2 {
    color: #4b90cd;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}
h3 {
    color: #002d62;
    font-weight: 500;
    font-size: 16px;
}
h4 {
    font-weight: 100;
    font-size: 18px;
    color: #002d62;
}
p {
    color: #002d62;
    font-size: 14px;
}
a {
    
}
label {
    display: block;
    margin-bottom: 5px;
    color: #002d62;
}

input[type="text"],
input[type="email"],
textarea {
    width: 90%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #004085;
}
.App {
  text-align: center;
}
.App a {
	color: #4b90cd;
    text-decoration: none;
}
.App a:hover {
	color: #fdb827;
}

.App a:active {
	color: #000000;
}

.Home {
    background-image: url(./assets/bg/redrocksRunC.webp);
	background-size: cover;
    background-position: top;
    width: 100%;
    padding-bottom: 100px;
    margin: 0px auto;
    position: relative;
    overflow: hidden;
    margin-top: 110px;
	height: auto;
	padding: 40px 0px;
}
.BackgroundVideo {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Keep the video behind other content */
}
.Home .Container {
	max-width: 1200px;
	margin: 0 auto;
}
.Home .Container section {
    width: 45%;
    margin: 5px;
}
.Home .Container a:hover .Icon {
    color: #fdb827;
}
.Home section {
	display: inline-block;
	height: auto;
	vertical-align: middle;
	align-items: stretch;
}
.Home img.App-logo {
    width: 410px;
}
.Home img.Tagline {
    width: 520px;
}
.Home .Container section.Services {
    display: inline-block;
    width: 1200px;
}
.Home .ServiceList {
	border-top: 1px solid #fdb827;
    display: inline-block;
    width: 23%;
	margin: 30px 0px;
    padding: 50px 0px;
}
.Home .ServiceList .Description h2 {
	color: #ffffff;
    font-weight: bolder;
}
.Home .Icon {
	width: 95px;
	height: 95px;
	background-color: #4b90cd;
    border-radius: 60px;
    margin: 0 auto;
    transition: background 0.5s ease-out 150ms;
}
.Home .Icon:hover {
	background-color: #fdb827;
    transition: background 0.5s ease-out 150ms;
}
.Home .Icon img {
    height: 50px;
	padding: 21px 14px;
}
.Home .ServiceList:nth-child(2) .Icon img {
	padding: 21px 17px;
}
.Home .ServiceList:last-child .Icon img {
    height: 45px;
	padding: 23px 17px 12px 10px;
}
.Home p {
	font-size:22px;
    font-weight: bold;
	color: #4b90cd;
	padding-left: 15px;
}
.About {
    background-color: #fdb827;
    height: 400px;
}
.Fitness {
    display: none;
    /* width: 100%;
    background-color: #002d62;
	height: auto;
	display: block;
	vertical-align: top;
	margin: 0px auto;
	text-align: center;
	color: #002d62; */
}
.Fitness h2 {
    color: #002d62;
    font-size: 22px;
    text-align: left;
    transition: color 1s ease-out 100ms;
}
.Fitness h2:hover {
    color: #4b90cd;
}
.Fitness p {
    font-size: 24px;
}
.Fitness .column {
    display: inline-block;
    max-width: 400px;
    width: 33%;
    margin: 50px 0px;
    height: 350px;
    vertical-align: top;
    padding: 0px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
}
.Fitness .column img {
    width: 100%;
    height: 100%;
}

.Rates .Container {
	max-width: 1200px;
	margin: 0 auto;
}
.packages {
    margin: 0 auto;
    width: 100%;
    clear: both;
    margin-top: 40px;
}

/* ======================= */
.features-table {
    display: table;
    width: 100%;
    margin-top: 50px;
    padding: 0px 10px;
    border-collapse: collapse;
}

.header-row, .feature-row {
    display: table-row;
}

.feature-header, .option-header, .feature-name, .feature-option {
    display: table-cell;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    vertical-align: middle;
}

.feature-header {
    background-color: #f0f0f0;
    font-weight: bold;
}

.option-header {
    background-color: #4b90cd;
    color: white;
    text-transform: uppercase;
}

.feature-name {
    text-align: left;
    background-color: #f9f9f9;
    color:  #002d62;
    font-size: 14px;
}

.feature-option {
    text-align: center;
}

/* ============================= */


.InfoBlock {
    background-color: #ffffff;
	display: inline-block;
	width: 47%;
	margin: 50px 5px;
    vertical-align: top;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-bottom-width: 4px;
    transition: border 1s ease-out 100ms;
}
.Rates .InfoBlock:hover {
    border: 1px solid #4b90cd;
    border-bottom-width: 4px;
}
.InfoBlock .fitnessRateImage {
    width: 100%;
}
.InfoBlock h4 {
	text-align: left;
	font-weight: 100;
	padding-left: 15px;
	font-size:20px;
}
.signUp {
    max-width: 100%;
    text-align: right;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px;
}
span.individualButton {
    margin:0px 0px 0px 5px;
    text-transform: uppercase;
}
.InfoBlock h4 span, .individualButton {
	text-align: right;
	background-color: #fdb827;
	float: right;
	margin-top: -5px;
	margin-right: 15px;
	padding: 3px 10px;
    border-radius: 10px;
    font-size: 18px;
    text-transform: uppercase;
}
.InfoBlock h4 span a, .individualButton a {
	color: #ffffff;
    transition: color 1s ease-out 100ms;
}
.InfoBlock h4 span a:hover, .individualButton a:hover {
	color: #002d62;
    transition: color 1s ease-out 100ms;
}
.InfoBlock ol {
	border-bottom: solid 1px #dddddd;
	height:200px;
    transition: border 1s ease-out 100ms;
}
.InfoBlock ol:hover {
	border-bottom: solid 1px #4b90cd;
}
.InfoBlock ol li {
	list-style-type: circle;
	text-align: left;
    font-size: 14px;
    color: #002d62;
}
.InfoBlock ul li, .Schedules ul li {
	list-style-type: none;
	text-align: left;
}
/* ================================ */
.Schedule {
    /* background-image: url(./assets/bg/group-of-runners.jpg); */
	background-size: cover;
    background-position: bottom;
    width: 100%;
    padding-bottom: 100px;
    margin: 0px auto;
}
.Schedule section {
    display: inline-block;
    vertical-align: top;
    max-width: 1200px;
    margin: 0 auto;
}
.Schedule img {
    display: block;
    margin: 0 auto;
}
.dailySchedule {
    color: #002d62;
    background-color: #ffffff;
    display: inline-block;
    width: 500px;
    vertical-align: top;
    /* border: 3px solid #e6e6e6; */
    /* border-bottom-width: 6px; */
    transition: border 1s ease-out 100ms;
}
.dailyScheduleImages {
    width: 500px;
    vertical-align: top;
    margin-bottom: 80px;
}
.dailyScheduleImages img {
    max-width: 90%;
}
.dailyScheduleImages img.photo {
    border: solid 6px #e6e6e6;
}
.dailyScheduleImages img.photo2 {
    margin-top: -45px;
    margin-left: 35px;
    border: solid 6px #ffffff;
    position: relative;
}
/* .dailySchedule:hover {
    border: 3px solid #fdb827;
    border-bottom-width: 6px;
    transition: border 1s ease-out 100ms;
} */
.dailySchedule .border {
    border-bottom: 1px solid #e6e6e6;
    transition: border 1s ease-out 100ms;
    padding-bottom: 20px;
}
/* .dailySchedule .border:hover {
    border-bottom: 1px solid #fdb827;
    transition: border 1s ease-out 100ms;
} */
.dailySchedule iframe {
    padding: 30px 0px;
    max-width: 90%;
}
.day {
    display: inline-block;
    text-align: left;
    padding-right: 10px;
    width: 110px;
}
.times {
  display: inline-block;
  width: 60px;
  background: #4b90cd;
  color: white;
  padding: 2px 6px;
  margin-right: 5px;
  border-radius: 5px;
  text-align: right;
}
section.socialRow {
    display: block;
}
section.socialRow h2 {
    font-size: 3em;
}
section.socialRow .socialImages {
    display: flex;
}
section.socialRow .socialImages img {
    width: 32%;
    margin-bottom: 30px;
}
section.socialRow .socialImages img:first-child {
    padding-left: 0px;
}
img.photoCollage {
    margin-top: 50px;
    max-width: 1200px;
}
img.qrCode {
	max-width: 200px;
	position: relative;
}
img.scheduleTitle {
	max-width: 600px;
    padding: 70px 0px;
}
.Rates img.scheduleTitle {
    padding-bottom: 20px;
}
/* ===================================== */
#Calendar {
    overflow: hidden;
}
section.eventCalendar {
    max-width: 1200px;
    margin: 0 auto;
}
.calendar {
    display: inline-block;
    vertical-align: top;
    width: 47%;
    font-size: 13px;
    border: 3px solid #e6e6e6;
    border-bottom-width: 6px;
    background-color: #ffffff;
    padding-bottom: 20px;
    transition: border 1s ease-out 100ms;
}
.calendar:first-child {
    margin-right: 1%;
}
.calendar:hover {
    border: 3px solid #fdb827;
    border-bottom-width: 6px;
    transition: border 1s ease-out 100ms;
}
.calendar .calendar-header h3 {
    color: #002d62;
}

.header, .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}
.day-header, .daytime {
    padding: 5px;
    border: 1px solid #ddd;
    text-align: center;
}

.day-header {
    background-color: #f0f0f0;
}

.empty {
    background-color: #eaeaea;
}

.activity {
    margin-top: 5px;
    padding: 3px;
    color: white;
    border-radius: 4px;
    font-size: 0.8em;
}

.key {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.key-item {
    margin-right: 10px;
    align-items: center;
    width: 33%;
}
.address span {
    display: inline-block;
    vertical-align: top;
    width: 35%;
    font-size: 10px;
}
.address span.sept {
    display: inline-block;
    width: 25%;
    font-size: 10px;
    vertical-align: top;
}

.key-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}
/* ====================================== */
.Carousel {
    max-width: 1200px;
    margin: 0 auto 70px auto;
    height: 800px;
    overflow: hidden;
}
/* ====================================== */
#massage {
    background-image: url(./assets/bg/massagebg-3.png);
	background-size: cover;
    background-position: bottom;
    padding-bottom: 80px;
}
.Massage {
    max-width: 1200px;
    margin: 0 auto;
}
.MassageHeader img {
    width: 600px;
}
.Massage {
    color: #002d62;
}
.Massage h2 {
    text-align: center;
    border-bottom: 1px solid #ffffff;
    height: 70px;
}
.Massage h3 {
    font-weight:600;
    font-size: medium;
    padding: 0px 30px;
}
.Massage .column {
    vertical-align: top;
    display: inline-block;
    width: 40%;
    padding: 10px;
    text-align: left;
}
.Massage .column img {
    width: 300px;
    text-align: center;
}
.testimonial-slider {
  max-width: 100%;
  height: auto;
  background-color: #4b90cd;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 75px 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  transition: all 0.5s ease;
}
.testimonial-image {
  width: 300px; 
  height: 300px; 
  object-fit: cover; /* Ensures the images are nicely cropped */
  border-radius: 50%; /* Makes image circular, remove if unwanted */
  margin-bottom: 10px;
  border: dashed 6px #fdb827;
}
.testimonial-item p {
  font-size: 16px;
  color: #002d62;
  width: 70%;
  margin: 0 auto;
}

p.author {
  margin-top: 10px;
  font-weight: bold;
  color: #fdb827;
}

.Contact {
    align-items: center;
    padding: 20px 0px; 
    margin-bottom: 50px;
}

.ContactContainer {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}
.ContactContainer .column {
    width: 33%;
    display: inline-block;
    vertical-align: top;
}
.App-logo-footer {
    width: 300px;
}
.ContactContainer .column img.qrCode {
    max-width: 100px;
}
.ContactContainer .column img.qrCode:hover {
    border: solid 1px #fdb827;
}


.SocialLinks a {
    display: inline-block;
    color: #0056b3;
    text-decoration: none;
}
.SocialLinks a:hover {
    text-decoration: underline;
    color: #fdb827;
}
.SocialLinks p {
    text-transform: uppercase;
    color: #004085;
}
.SocialLinks .social-icons {
    padding: 10px;
    width: 75px;
}
.SocialLinks .social-icons:hover {
    border-radius: 60px;
    transition: border 0.3s ease;
    /* border: solid 1px #fdb827; */
}


/* ======================================
			MEDIA QUERIES
====================================== */

@media screen and (min-width: 1200px) {
    
}

@media screen and (max-width: 1199px) {
    .packages {
        width: 95%;
        margin: 40px auto;
    }
    .InfoBlock {
        width: 48%;
        margin: 0px;
        padding: 0px;
    }
    .InfoBlock:first-child {
        margin-right:2%;
    }
    .Home section {
		display: inline-block;
		margin: 0 auto;
		width: 100%;
	}
    .Home img.Tagline {
        max-width: 390px;
        padding: 80px 0px;
    }
    .Home .Services {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 100%;
    }
    .Home .ServiceList {
        display: inline-block;
        margin: 0 auto;
        width: 50%;
    }
    .Home .ServiceList:last-child {
        border-bottom: none;
        padding-bottom: 10px;
    }
    img.photoCollage {
        width: 95%;
    }
    .features-table {
        width: 95%;
        margin: 0 auto;
        border-collapse: collapse;
        font-size: smaller;
    }
    .dailySchedule {
        width: 600px;
        margin-bottom: 30px;
    }
    .calendar {
        margin-left: 0px;
    }
}
@media screen and (max-width: 992px) {
	.Home section {
		display: inline-block;
		margin: 0 auto;
		width: 100%;
	}
    .Fitness .column {
        width: 85%;
        margin: 25px 0px;
    }
    .Massage .column {
        width: 70%;
        padding: 10px;
    }
    .Massage h2 {
        height: auto;
    }
    .calendar {
        display: block;
        margin: 0 auto;
        width: 90%;
        margin-bottom: 20px;
    }
    .calendar:first-child {
        margin-right: auto;
    }
}

@media screen and (max-width: 768px) {
    .Home {
        padding: 30px 0px;
    }
    .Home .Container section {
        width:100%;
    }
    .Home section {
        margin: 0 auto;
		display: block;
	}
    .Home img.Tagline {
        padding: 20px 0px;
    }
    .Rates {
        padding-bottom: 50px;
    }
    img.scheduleTitle {
    	width: 90%;
        padding: 40px 0px;
    }
    .packages {
        width: 95%;
        margin: 40px auto;
    }
    .InfoBlock {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
    .InfoBlock:first-child {
        margin:0px 0px 30px 0px;
    }
    .Schedule section {
        display: block;
    }
    .dailySchedule {
        width: 95%;
        margin: 0px auto 30px auto;
    }
    .signUp {
        padding: 10px;
        text-transform: uppercase;
        font-size: 10px;
        text-align: center;
    }
    .signUp h2 {
        padding-top: 0px;
    }
    span.individualButton {
        margin: 10px 5px 10px 5px;
        text-transform: uppercase;
        vertical-align: middle;
    }
    .header, .days {
        font-size: smaller;
    }
    .header, .address {
        font-size: 9px;
    }
    .ContactContainer .column {
        width: 90%;
        display: block;
        border-bottom: 1px solid #fdb827;
        margin: 0 auto;
        padding-bottom: 10px;
    }
    .ContactContainer .column:last-child {
        width: 90%;
        display: block;
        border-bottom: none;
        margin: 0 auto;
    }
}
@media screen and (max-width: 576px) {
    .day {
        display: block;
        text-align: center;
        width: 100%;
    }
    .times {
        display: inline-block;
        width: 58px;
        padding: 4px 4px;
        margin-right: 3px;
        border-radius: 5px;
        text-align: center;
    }
    iframe {
        max-width: 90%;
    }
    .Home img.App-logo, .Home img.Tagline {
        width: 280px;
    }
    .Home .Description {
        padding-left: 5px;
    }
    .Home p {
        font-size: 18px;
    }
    .Home .ServiceList {
        display: block;
        width: 100%;
        max-width: 450px;
        padding: 20px 0px 0px 0px;
    }
    .Home .Icon {
        width: 95px;
        height: 95px;
    }
    .option-header {
        font-size: 12px;
    }
    .Fitness .column img {
        width: 100%;
        height: auto;
    }
    .dailyScheduleImages {
        width: 300px;
        margin-bottom: 50px;
    }
    .dailyScheduleImages img.photo2 {
        margin-top: -45px;
        margin-left: 75px;
    }
    section.socialRow .socialImages {
        display: flex;
    }
}
