@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
}

nav {
	height: 110px;
	background-color: #ffffff;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 100;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.NavContainer {
  max-width: 1200px;
  margin: 0 auto;
}

.nav-elements img.App-logo-nav {
	display: flex;
    justify-content: space-between;
	float:left;
    height: 90px;
    padding-top: 10px;
}
.NavContainer img.App-logo-nav-mobile {
	display: none;
}
.nav-elements ul {
  display: flex;
  list-style-type: none;
  float: right;
  padding-top: 45px;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #4b90cd;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #fdb827;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #002d62;
}
@media screen and (max-width: 1199px) {
    .NavContainer {
        padding: 0px 20px;
    }
}
@media (max-width: 992px) {
    nav {
    	height: 110px;
    }
    .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
	text-align: right;
  }
  .menu-icon {
    display: block;
    cursor: pointer;
  }
  .nav-elements img.App-logo-nav {
  	display: none;
  }
  .NavContainer img.App-logo-nav-mobile {
  	display: inline-block;
    height: 90px;
    padding-top: 10px;
    float:left;
  }
  #nav-icon {
    width: 60px;
    height: 45px;
    position: fixed;
	top: 30px;
	right: 30px;
    margin: 0px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: #4b90cd;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  #nav-icon span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  #nav-icon span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
  }

  #nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {
    top: 0px;
  }

  #nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {
    top: 18px;
  }

  #nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {
    top: 36px;
  }

  #nav-icon.open span:nth-child(1),#nav-icon.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon.open span:nth-child(2),#nav-icon.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }

  #nav-icon.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }

  #nav-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  #nav-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  #nav-icon.open span:nth-child(5) {
    left: 5px;
    top: 29px;
  }

  #nav-icon.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 29px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 80px;
    background-color: #ffffff;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 170px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
